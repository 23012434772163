/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.menu-pagelist {
    min-height: 5vh;
    max-height: 50vh;
    overflow: auto;
    min-width: 4vw;
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translate(31px, 35px)
}

.table > thead > tr > th {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    line-height: 1;
}
    
.table-sm > :not(caption) > * > * {
    padding: 0.1rem 0.1rem;
}


