.content-offcanvas {
  position: fixed;
  width: 35rem !important;
  top: calc($navbar-top-height + 1px) !important;
  height: calc(100vh - ($navbar-top-height + $footer-height)) !important;
  @include media-breakpoint-up(sm) {
    background-position: 100% !important;
  }
}

.offcanvas-backdrop-transparent {
  ~ {
    .offcanvas-backdrop {
      opacity: 0;
    }
  }
}

.phoenix-offcanvas-container {
  position: relative;
  overflow: hidden;
}

.phoenix-offcanvas {
  position: absolute;
  overflow: auto;
  top: 0;
  bottom: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 1045;
  transform: translateX(-100%);
  max-width: 100%;
  &.phoenix-offcanvas-fixed {
    position: fixed;
    transform: translateX(-100%);
  }
  &.phoenix-offcanvas-start {
    left: 0;
    transform: translateX(-100%);
  }
  &.phoenix-offcanvas-end {
    transform: translateX(100%);
    right: 0;
  }
  &.phoenix-offcanvas-top {
    transform: translateY(-100%);
  }
  &.phoenix-offcanvas-bottom {
    transform: translateY(100%);
  }
  &.show {
    transform: none;
    ~ {
      .phoenix-offcanvas-backdrop {
        position: fixed;
        top: 92px;
        left: 0;
        height: 100vh;
        width: 100vw;
        background: rgba($black, $alpha: 0.1);
        z-index: 8;
      }
    }
  }
}
