/*!****************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!./node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/assets/scss/user.scss ***!
  \****************************************************************************************************************************************************************************/
/*-----------------------------------------------
|   Phoenix buttons
-----------------------------------------------*/
/* -------------------------------------------------------------------------- */
/*                                Phoenix button                              */
/* -------------------------------------------------------------------------- */
/* prettier-ignore */
/* ----------------------------- Phoenix-buttons ---------------------------- */

